// import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import LandingPage from "components/LandingPage";
import Head from "next/head";
import Script from "next/script";
import React from "react";

function Home() {
  return (
    <>
      <Head>
        <title>Indoor Ascents</title>
        <meta
          name="description"
          content="Indoor Ascents, the premire rock climbing training app"
        />
        <meta property="og:title" content="Indoor Ascents" />
        <meta
          property="og:description"
          content="The premire rock climbing training app"
        />
        <meta property="og:url" content="https://indoorascents.com/" />
        <meta property="og:type" content="website" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <LandingPage />

      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=UA-226834898-1`}
      />
      <Script
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'UA-226834898-1', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />
    </>
  );
}

export default Home;
