import { AuthAction, useAuthUser, withAuthUser } from "next-firebase-auth";
import { useRouter } from "next/router";
import React, { useState } from "react";
import IALink from "./common/IALink";

import Logo from "./common/Logo";
import UserNavbarIcon from "./common/UserNavbarIcon";

function LandingPageNavBar() {
  let userActionsButtons;


  const authUser = useAuthUser();
  const router = useRouter();

  const inviteCodeParam = router.query.ic
  const [inviteCode, setInviteCode] = useState<string | null | undefined>(undefined)

  // TODO: This is terrible
  if (inviteCodeParam && !Array.isArray(inviteCodeParam) && inviteCode === undefined) {
    setInviteCode(`?ic=${inviteCodeParam}`)
  } else if (inviteCode === undefined) {
    setInviteCode(null)
  }

  // We will get an authed user here but if it doesn't have an email then we assume they are not logged in
  if (authUser?.email != null) {
    userActionsButtons = (
      <>
        <UserNavbarIcon authUser={authUser} />
      </>
    );
  } else {
    userActionsButtons = (
      <>
        <IALink href="/auth" passHref>
          <button className="btn btn-ghost hover:underline m-1">Log in</button>
        </IALink>
        <IALink href={`/signup${inviteCode ? inviteCode : ""}`} passHref>
          <button className="btn btn-primary m-1 hidden sm:flex">
            Sign up
          </button>
        </IALink>
      </>
    );
  }

  const navbarButtons = (
    <>
      <IALink href="/gyms" passHref>
        <button className="btn btn-ghost hover:underline">For Gyms</button>
      </IALink>
      <IALink href="/about" passHref>
        <button className="btn btn-ghost hover:underline">About</button>
      </IALink>
      {userActionsButtons}
    </>
  );

  return (
    <div className="navbar shadow-lg bg-neutral text-neutral-content">
      <div className="flex-1 px-2  sm:ml-14 md:ml-16 lg:ml-40">
        <Logo />
        <IALink href={"/"} passHref>
          <span className="text-lg font-bold hidden sm:flex">
            Indoor Ascents
          </span>
        </IALink>
      </div>
      <div className="flex-initial  sm:mr-14 md:mr-16 lg:mr-40">
        {navbarButtons}
      </div>
    </div>
  );
}

export default withAuthUser({
  whenAuthed: AuthAction.RENDER,
  whenUnauthedBeforeInit: AuthAction.RENDER,
  whenUnauthedAfterInit: AuthAction.RENDER,
  LoaderComponent: null,
})(LandingPageNavBar);
