import React from "react";
import { useRouter } from "next/router";
import Image from "next/image";

const EmailListComponent = ({
  title,
  body,
}: {
  title?: string;
  body?: string;
}) => {
  const [state, setState] = React.useState({
    email: "",
    allowResubmit: true,
    buttonText: "Subscribe",
  });

  const router = useRouter();
  const baseUrl = router.basePath;

  const onChange = (e: React.FormEvent<HTMLInputElement>): void => {
    setState({ ...state, email: e.currentTarget.value });
  };

  const submitEmailSignup = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // don't redirect the page

    if (state.allowResubmit) {
      await fetch(baseUrl + "/api/email-list", {
        body: JSON.stringify({
          email: state.email,
        }),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
    }

    setState({
      email: "",
      allowResubmit: false,
      buttonText: "Added!",
    });
  };

  return (
    <div className="card card-bordered w-full max-w-sm text-center shadow-2xl">
      <figure>
        <Image src={"/email-2.jpg"} width={400} height={250} alt="climber" />
      </figure>
      <div className="card-body">
        <h2 className="card-title text-primary">
          {title ? title : "Get Indoor Ascents Updates"}
        </h2>
        <p>
          {body
            ? body
            : "Sign up to receive news and information about what's new with Indoor Ascents"}
        </p>
        <div className="justify-center card-actions">
          <div className="card bg-base-200 form-control px-4 py-2">
            <form onSubmit={submitEmailSignup}>
              <div className="flex space-x-2">
                <input
                  type="text"
                  placeholder="Email"
                  className="w-full input input-primary input-bordered"
                  id="emailInput"
                  autoComplete="email"
                  value={state.email}
                  disabled={!state.allowResubmit}
                  required
                  onChange={onChange}
                />
                {/* TODO: Add a popup to verify they are real */}
                <button className="btn btn-accent" type="submit">
                  {state.buttonText}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailListComponent;
