/* eslint-disable @next/next/no-img-element */
import { useRouter } from "next/router";
import React from "react";
import IALink from "./IALink";

interface LogoProps {
  width?: number;
  redirectToLanding?: boolean;
  redirect?: string;
}

const baseRoute = "/";

const Logo = function LinkButton({
  redirectToLanding = true,
  redirect,
}: LogoProps) {
  let classStuff = "w-10 rounded-full mx-2 ";

  if (redirectToLanding) {
    classStuff = classStuff + "hover:cursor-pointer";
  }

  let baseImage = (
    <div className={classStuff}>
      <img
        className="object-contain"
        src="/img/logo300x300.png"
        alt="Indoor Ascents Logo"
      />
    </div>
  );

  const router = useRouter();
  const route = router.route;

  if (route !== baseRoute && redirectToLanding) {
    baseImage = (
      <IALink href={redirect ?? "/"} passHref>
        {baseImage}
      </IALink>
    );
  }

  return baseImage;
};

export default Logo;
