import { AuthUser } from 'next-firebase-auth';
import React from 'react';
import IALink from './IALink';
import LogoutButton from './LogoutButton';

interface UserNavbatIconProps {
    authUser: AuthUser;
}

const UserNavbarIcon = function UserNavbarIcon({
    authUser,
}: UserNavbatIconProps) {
    return (
        <div className="avatar placeholder dropdown dropdown-hover dropdown-end">
            <div className="bg-secondary text-neutral-content rounded-full w-12 h-12 md:p-1 btn">
                {/* TODO: Once we have a way to set profile pics use that here */}
                <span>{authUser.email?.substring(0, 2).toUpperCase()}</span>
            </div>

            {/* TODO: Move this to somewhere common sot aht we can use it for the homepage as well */}
            <ul
                tabIndex={0}
                className="p-2 shadow menu dropdown-content bg-secondary rounded-box w-52"
            >
                <IALink href="/loginRedirect">
                    <a className="btn button-primary mx-1">Dashboard</a>
                </IALink>
                <div className="pt-1" />
                <IALink href="/user/settings">
                    <a className="btn button-primary pt-1 mx-1">Settings</a>
                </IALink>
                <LogoutButton authUser={authUser} />
            </ul>
        </div>
    );
};

export default UserNavbarIcon;
