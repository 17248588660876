import { AuthUser } from "next-firebase-auth";
import { useRouter } from "next/router";
import React from "react";
import getLogoutFunction from "utils/getLogoutFunction";
import { isMobileApp } from "utils/useDeviceType";

interface LogoutButtonProps {
  authUser: AuthUser;
  redirectLocation?: string;
  color?: "btn-primary" | "btn-accent";
}

const LogoutButton = function LinkButton({
  authUser,
  redirectLocation,
  color = "btn-primary",
}: LogoutButtonProps) {
  const router = useRouter();

  const classSpecs = `btn ${color}`;
  const isMobile = isMobileApp();

  const defualtRedirectLoc = isMobile ? "/mobileLanding" : "/";
  const redirectLoc = redirectLocation ? redirectLocation : defualtRedirectLoc;

  return (
    <li className="m-1" key="logout">
      <a
        className={classSpecs}
        onClick={getLogoutFunction(authUser, router, redirectLoc)}
      >
        Logout
      </a>
    </li>
  );
};

export default LogoutButton;
