/* eslint-disable @next/next/no-img-element */
// import { AuthUser, useAuthUser } from "next-firebase-auth";
import React from "react";
import EmailListComponent from "./EmailListComponent";
import Footer from "./Footer";
import LandingPageNavBar from "./LandingPageNavBar";
import Image from "next/image";
import { BrowserView, MobileView } from "react-device-detect";

export default function LandingPage() {
  return (
    <div className="flex flex-col h-screen">
      <LandingPageNavBar />
      <div className="hero min-h-screen relative bg-black">
        <div className="hero-overlay bg-opacity-60 z-10"></div>
        <div className="text-center hero-content text-neutral-content z-10">
          <div className="max-w-md">
            <h1 className="mb-5 text-6xl font-bold">
              <span className="sm:pr-20">By Climbers, </span>
              <br />
              <span className="sm:pl-20">For Climbers</span>
            </h1>
            <p className="mb-5 text-xl">
              Welcome to Indoor Ascents, the premier rock climbing training app.
              We are on a mission to connect climbers with gyms through data.
              Keep reading to learn more.
            </p>
            <div className="flex flex-row space-x-2 justify-center items-center">
              <a href='https://apps.apple.com/us/app/indoor-ascents/id1633581071?platform=iphone'>
                <img alt='Get it on the App Store' src='/img/app-store-badge.svg' height={60} />
              </a>
              <a href='https://play.google.com/store/apps/details?id=com.indoorascents.twa&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                <img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' className="max-h-16" />
              </a>
            </div>
          </div>
        </div>
        <BrowserView>
          <Image
            alt="Climber"
            src="/img/landing-page-image-small.jpg"
            layout="fill"
            objectFit="cover"
            className="z-0"
            priority={true}
          />
        </BrowserView>
        <MobileView>
          <Image
            alt="Climber"
            src="/img/landing-page-image-small.jpg"
            layout="fill"
            objectFit="cover"
            quality={80}
            className="z-0"
            priority={true}
          />
        </MobileView>
      </div>

      <div className="hero min-h-fit py-5 bg-primary">
        <div className="flex-col justify-center hero-content lg:flex-row text-primary-content">
          <div className="text-center lg:text-left lg:pr-40">
            <h1 className="mb-5 text-6xl font-bold text-accent">
              How it works:
            </h1>
            <ol className="list-decimal list-inside mb-5 text-xl indent-4 marker:font-extrabold marker:text-3xl marker:text-accent">
              <li>Go to the gym</li>
              <li>Scan your Indoor Ascents card before each climb</li>
              <li>That&apos;s it! You&apos;ve recorded all of your climbs</li>
            </ol>
            <p className="mb-5 text-xl">
              Indoor Ascents was designed to be the least intrusive climbing
              fitness app on the market. Our patent pending technologies allow
              you to record every ascent of your session without ruining the
              climbing experience.{" "}
              <a className="font-bold">
                No need to carry your phone while climbing.{" "}
              </a>
              No need to manually record ascents.
            </p>
            <p className="mb-5 text-xl">
              In addition to recording all your ascents, Indoor Ascents uses
              this data to help you train. As you record climbs, we are doing
              work on our end to suggest climbs for your next climbing session.
              Mark routes as projects and we&apos;ll let you know when they are
              about to be reset.
            </p>
          </div>
          <div className="w-[100%] sm:w-[50%] lg:w-[130%]">
            <Image
              src={"/img/scanner.png"}
              layout={"responsive"}
              width={500}
              height={375}
              alt="scanners"
              priority={true}
            />
          </div>
        </div>
      </div>
      <div className="hero min-h-fit py-20 bg-base-200">
        <div className="flex-col justify-center hero-content lg:flex-row">
          <div className="text-center lg:text-left lg:pr-40">
            <h1 className="mb-5 text-6xl font-bold text-primary">
              Why Indoor Ascents?
            </h1>
            <p className="mb-5 text-xl">
              Indoor Ascents is all about connecting climbers, route setters and
              gyms through data.
            </p>
            <ul className="list-disc list-outside md:mb-5 pl-12 marker:font-extrabold marker:text-3xl marker:text-primary text-xl font-light">
              <li>Set goals and monitor your progress</li>
              <li>
                Get the most out of your climbing sessions by preparing ahead
                and reviewing afterwards
              </li>
              <li>
                Avoid the crowds to get the best training session possible
              </li>
            </ul>
            <p className="mb-5 text-xl">
              We have the tools that climbers need to climb harder.
            </p>
          </div>
          <EmailListComponent />
        </div>
      </div>

      <Footer />
    </div>
  );
}
