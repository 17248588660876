

import Link, { LinkProps } from "next/link";
import React from "react";

// TODO: make the loader in the center of the page
function IALink(props: React.PropsWithChildren<LinkProps>, hoverPointer?: boolean) {

  if (hoverPointer == true) {
    return <div className="hover:cursor-pointer"><Link {...props} passHref /></div>
  }

  return <Link {...props} passHref />
}

export default IALink;