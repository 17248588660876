import {
    faInstagram,
    faLinkedin,
    faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import IALink from './common/IALink';

export default function Footer() {
    return (
        // <footer className="items-center p-4 footer-center bg-neutral text-neutral-content">
        //     <div className="items-center grid-flow-col">
        //         <p>Copyright © 2022 - All right reserved</p>
        //     </div>
        // </footer>
        <footer className="grid grid-rows-3 md:grid-cols-3 md:grid-rows-1 w-full text-center p-4 bg-neutral text-neutral-content">
            <div>
                <p>Contact: info@indoorascents.com</p>
            </div>
            <div className="grid-row-3">
                <p>© Indoor Ascents LLC - All rights reserved</p>
            </div>
            <div className="grid-row-2 md:grid-row-1">
                <IALink
                    href={'https://www.linkedin.com/company/indoorascents'}
                    passHref
                >
                    <span>
                        <FontAwesomeIcon
                            icon={faLinkedin}
                            size={'2x'}
                            className="mx-2"
                        />
                    </span>
                </IALink>
                <IALink
                    href={'https://www.instagram.com/indoorascents/'}
                    passHref
                >
                    <span>
                        <FontAwesomeIcon
                            icon={faInstagram}
                            size={'2x'}
                            className="mx-2"
                        />
                    </span>
                </IALink>
                <IALink href={'https://twitter.com/IndoorAscents'} passHref>
                    <span>
                        <FontAwesomeIcon
                            icon={faTwitter}
                            size={'2x'}
                            className="mx-2"
                        />
                    </span>
                </IALink>
            </div>
        </footer>
    );
}
