import { AuthUser } from "next-firebase-auth";
import { NextRouter } from "next/router";


export default function getLogoutFunction(authUser: AuthUser, router: NextRouter, redirectLoc?: string ): () => Promise<void> {

  const thing = async () => {
    await authUser.signOut();

    if(redirectLoc) {
      router.push(redirectLoc);
    } else {
      router.back()
    }
  }

  return thing

}