import { useEffect, useState } from "react";
import { deviceType, isAndroid, isIOS, isMobile } from "react-device-detect";
import { IS_APP_COOKIE } from "./consts";
import { getCookie, setCookie } from "cookies-next";
import { SSRPropsContext } from "next-firebase-auth";

export enum DeviceType {
  androidApp,
  iosApp,
  mobile,
  browser,
}

export default function useDeviceType(): DeviceType | null {
  const [state, setState] = useState<DeviceType | null>(null);

  const setDeviceState = function (type: DeviceType | null) {
    if (isDeviceTypeMobileApp(type)) {
      setCookie(IS_APP_COOKIE, "true", { maxAge: 100 * 60 * 60 * 24 * 1000 });
    } else {
      console.log("setting false");
      console.log(deviceType);
      // setCookie(IS_APP_COOKIE, "false", { maxAge: 100 * 60 * 60 * 24 * 1000 })
    }
    setState(type);

    return;
  };

  // If we have info from iOS that it's an app then we trust it
  if (getCookie("app-platform") === "iOS App Store") {
    setDeviceState(DeviceType.iosApp);

    // If it is marked as an app then we trust it
  } else if (getCookie(IS_APP_COOKIE) === "true") {
    console.log("Marked app");
    setDeviceState(DeviceType.iosApp);

    // iOS app that doesn't seem to work
    // TODO: Figure out if we can do some other query to get this to work
  }

  useEffect(() => {
    if (deviceType) {
      return;
    }

    if (window.matchMedia("(display-mode: standalone)").matches && isIOS) {
      setDeviceState(DeviceType.iosApp);
      // Android PWA app
    } else if (
      window.matchMedia("(display-mode: standalone)").matches &&
      isAndroid
    ) {
      setDeviceState(DeviceType.androidApp);
      // Mobile but didn't pass the other app checks
    } else if (process.env.NEXT_PUBLIC_IS_MOB_APP === "true") {
      // iPhone 6/7/8 Plus
      if (
        window.matchMedia("(device-width: 414px)").matches &&
        window.matchMedia("(device-height: 736px)").matches
      ) {
        setDeviceState(DeviceType.iosApp);
        return;
        // Galaxy s8+
      } else if (
        window.matchMedia("(device-width: 360px)").matches &&
        window.matchMedia("(device-height: 740px)").matches
      ) {
        setDeviceState(DeviceType.androidApp);
        return;
      }
      // All else are deemed browser
    } else if (isMobile) {
      setDeviceState(DeviceType.mobile);
      // Local debug mobile
    } else {
      setDeviceState(DeviceType.browser);
    }
  }, [setState, state]);

  return state;
}

export function isDeviceTypeMobileApp(deviceType: DeviceType | null): boolean {
  if (
    deviceType === DeviceType.iosApp ||
    deviceType === DeviceType.androidApp
  ) {
    return true;
  }

  return false;
}

export function isMobileApp(context?: SSRPropsContext): boolean | null {
  const isApp = getCookie(IS_APP_COOKIE, {
    req: context?.req,
    res: context?.res,
  });

  if (isApp || isApp === "true") {
    setCookie(IS_APP_COOKIE, "true", {
      req: context?.req,
      res: context?.res,
      maxAge: 100 * 60 * 60 * 24,
    });
    return true;
  } else if (isApp === false || isApp === "false") {
    setCookie(IS_APP_COOKIE, "true", {
      req: context?.req,
      res: context?.res,
      maxAge: 100 * 60 * 60 * 24,
    });
    return false;
  } else if (isApp === undefined || isApp === null) {
    return false;
  }

  console.log("No app state defined. Something is wrong here");
  return null;
}
